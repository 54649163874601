import { SupportAccountModels, SubcontractLineModels, NoteModels, FileModels, CostModels } from 'Models';
import { AwardStatusNames, AwardStatuses } from './AwardStatusModels';
import { RoleAssignment } from './RoleModels';
import { Team } from './TeamModels';
import { WorkItem } from './WorkItemModels';

// make this a separate interface so user models can make a union literal type out of this props
export interface IProposal {
	id: number;
	shortName: string;
	instituteName: string;
	awardStatusName: AwardStatusNames;
	proposalNumber: string;
	accountNumber: string;
	leadAgencyName: string;
	mNumber: string;
	startsOn?: Date;
	endsOn?: Date;
	contractSignedOn?: Date;
	fundingSourceName: string;
	grantProgramName: string;
	directCosts: number;
	indirectCosts: number;
	totalAward: number;
	directMatch: number;
	indirectMatch: number;
	unrecoveredIDC: number;
	totalMatch: number;
	isExpirationDismissed: boolean;
	isExtensionFiled: boolean;
	nextCostEndsOn?: Date;
}

/** for showing proposals on the home page */
export class Proposal implements IProposal {
	id = 0;
	shortName = '';
	instituteName = '';
	awardStatusName: AwardStatusNames = 'Submitted';
	proposalNumber = '';
	accountNumber = '';
	agencyNumber = '';
	leadAgencyName = '';
	mNumber = '';
	startsOn?: Date;
	endsOn?: Date;
	contractSignedOn?: Date;
	fundingSourceName = '';
	grantProgramName = '';
	directCosts = 0;
	indirectCosts = 0;
	totalAward = 0;
	directMatch = 0;
	indirectMatch = 0;
	unrecoveredIDC = 0;
	totalMatch = 0;
	isExpirationDismissed = false;
	isExtensionFiled = false;
	nextCostEndsOn?: Date;
	submittedOn?: Date;
	roleAssignments: RoleAssignment[] = [];
	// the following don't come from the api, but need to be present for table sorting to work
	principalInvestigator = '';
	primaryContact = '';
	businessContact = '';
	coPIs = '';
	teams: string[] = [];
}

export class ProposalDetails {
	id = 0;
	shortName = '';
	longName = '';
	abstract = '';
	instituteId = 0;
	instituteName = '';
	awardStatusId: AwardStatuses = AwardStatuses.Submitted;
	awardStatusName: AwardStatusNames = 'Submitted';
	proposalNumber = '';
	accountNumber = '';
	leadAgencyId = 0;
	leadAgencyName = '';
	mNumber = '';
	startsOn?: Date;
	endsOn?: Date;
	clientContractNumber = '';
	contractSignedOn?: Date;
	fundingSourceId = 0;
	fundingSourceName = '';
	fundingTypeId = 0;
	fundingTypeName = '';
	grantProgramId = 0;
	grantProgramName = '';
	submittedOn?: Date;
	createdOn?: Date;
	createdByName = '';
	supportAccounts: SupportAccountModels.SupportAccount[] = [];
	subcontractLines: SubcontractLineModels.SubcontractLine[] = [];
	notes: NoteModels.Note[] = [];
	files: FileModels.File[] = [];
	costs: CostModels.Cost[] = [];
	involvedParties: InvolvedParty[] = [];
	awardedProposal: AwardedProposal = new AwardedProposal();
	rejectedProposal: RejectedProposal = new RejectedProposal();
	withdrawnProposal: WithdrawnProposal = new WithdrawnProposal();
	facilitatedProposal: FacilitatedProposal = new FacilitatedProposal();
	linkedProposals: LinkedProposals[] = [];
	roleAssignments: RoleAssignment[] = [];
	teams: Team[] = [];
	workItems: WorkItem[] = [];
}

/** for the add proposal form */
export class AddProposal {
	proposalNumber = '';
	shortName = '';
	instituteId = 0;
	leadAgencyId = 0;
	fundingTypeId = 0;
	fundingSourceId = 0;
}

export class EditProposal {
	id = 0;
	proposalNumber = '';
	shortName = '';
	longName = '';
	abstract = '';
	startsOn?: Date;
	endsOn?: Date;
	submittedOn?: Date;
	instituteId = 0;
	leadAgencyId = 0;
	fundingTypeId = 0;
	fundingSourceId = 0;
	grantProgramId?: number;
	awardStatusId: AwardStatuses = AwardStatuses.Submitted;
	finalInvoiceDueOn?: number;
	mNumber: string | null = '';
	clientContractNumber?: string | null = '';
	awardedProposal?: AwardedProposal;
	rejectedProposal?: RejectedProposal;
	withdrawnProposal?: WithdrawnProposal;
	facilitatedProposal?: FacilitatedProposal;
}

export class AwardedProposal {
	awardedOn: Date = new Date();
	accountNumber?: string = '';
	agencyNumber?: string = '';
	isExpirationDismissed = false;
	isExtensionFiled = false;
	contractSignedOn?: Date;
}

export class RejectedProposal {
	rejectedOn: Date = new Date();
	note?: string = '';
}

export class WithdrawnProposal {
	withdrawnOn: Date = new Date();
	note?: string = '';
}

export class FacilitatedProposal {
	facilitatedOn: Date = new Date();
	accountNumber?: string = '';
	agencyNumber?: string = '';
}

export class InvolvedParty {
	proposalId = 0;
	organizationId = 0;
	organizationName = '';
}

export class LinkedProposals {
	proposalId = 0;
	linkedProposalId = 0;
	relatedProposalShortName = '';
}

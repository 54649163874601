import React, { useState } from 'react';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router';
import classnames from 'classnames';

import * as Pages from 'Pages';
import * as AdminPages from 'Pages/Admin';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import {
	Theme,
	CssBaseline,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	Hidden,
	IconButton,
} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import SearchIcon from '@material-ui/icons/Search';
import UsersIcon from '@material-ui/icons/SupervisedUserCircle';
import OrganizationIcon from '@material-ui/icons/AccountTree';
import HelpIcon from '@material-ui/icons/Help';
import { ProtectedComponent, ProtectedRoute } from 'Common/Utilities';
import { Link } from 'react-router-dom';
import { AppBar } from 'Layout/Navigation';
import sidebarBg from 'Assets/img/hay-bale-sidebar.jpg';
import { AddProposalForm } from 'Common/Forms/Add';
import { SignInManager } from 'Common/Auth';
import { Skeleton } from '@material-ui/lab';

interface ILayoutProps {
	isLoading: boolean;
}

const drawerWidth = 240;

const contentTheme = {
	overrides: {
		MuiPaper: {
			elevation1: {
				padding: 8,
				margin: 8,
				boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
			},
			elevation2: {
				padding: 8,
				margin: 8,
				boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.14)',
			},
		},
		MuiExpansionPanelSummary: {
			root: {
				'&$expanded': {
					borderBottom: '1px solid rgba(0, 0, 0, .125)',
				},
			},
		},
		MuiTypography: {
			h5: {
				marginTop: 8,
				marginBottom: 8,
			},
		},
		MuiFab: {
			root: {
				margin: 0,
				top: 'auto',
				right: 20,
				bottom: 20,
				left: 'auto',
				position: 'fixed' as 'fixed',
			},
		},
		MuiFormControl: {
			root: {
				margin: 4,
				minWidth: 175,
			},
		},
		MuiTableRow: {
			root: {
				'&:nth-child(even)': {
					backgroundColor: 'rgba(0,0,0,0.03)',
				},
				'&:empty': {
					display: 'none',
				},
			},
		},
		MuiTableCell: {
			body: {
				maxWidth: '300px',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
			},
		},
		MuiMenuItem: {
			root: {
				minHeight: 32,
			},
		},
		MuiChip: {
			label: {
				userSelect: 'auto',
			},
		},
	},
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		height: '100%',
	},
	appBarSpacer: theme.mixins.toolbar,
	toolbarIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	toolbarIcon: {
		color: '#fff',
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
		padding: theme.spacing(3),
	},
	title: {
		textTransform: 'inherit',
		marginBottom: theme.spacing(2),
	},
	mobileTitle: {
		marginTop: theme.spacing(2),
	},
	pageLinks: {
		textDecoration: 'none',
	},
	navItem: {
		margin: '4px',
		borderRadius: '3px',
		width: 'auto',
		'&:hover': {
			backgroundColor: 'rgba(255,255,255,0.23)',
		},
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		zIndex: 1000,
	},
	drawerPaper: {
		color: 'white',
		textTransform: 'uppercase',
		backgroundColor: 'rgba(0, 0, 0, .7)',
		backgroundImage: `url('${sidebarBg}')`,
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		padding: theme.spacing(2),
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	navSkeleton: {
		backgroundColor: 'rgba(255,255,255,.2)',
		marginRight: 8,
		marginLeft: 8,
	},
}));

const SkeletonBar = () => {
	const classes = useStyles();
	return (
		<Typography variant="h3">
			<Skeleton animation="wave" className={classes.navSkeleton} />
		</Typography>
	);
};

const NavContent = (props: { isLoading: boolean }) => {
	const classes = useStyles();

	if (props.isLoading) {
		return (
			<>
				<SkeletonBar />
				<SkeletonBar />
				<SkeletonBar />
			</>
		);
	}

	return (
		<React.Fragment>
			<Typography variant="h5" component="h1" className={`${classes.title} ${classes.mobileTitle}`}>
				Proposal Tracker
			</Typography>
			<Divider />
			<List>
				<Link to="/" className={classes.pageLinks}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<ListIcon />
						</ListItemIcon>
						<ListItemText primary="Proposals" />
					</ListItem>
				</Link>
				<Link to="/calendar" className={classes.pageLinks}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<CalendarIcon />
						</ListItemIcon>
						<ListItemText primary="Calendar" />
					</ListItem>
				</Link>
				<Link to="/budget-resources" className={classes.pageLinks}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon>
							<HelpIcon />
						</ListItemIcon>
						<ListItemText primary="Budget Resources" />
					</ListItem>
				</Link>

				<ProtectedComponent allowedRoles={['admin']}>
					<Divider />
					<Link to="/admin/grant-programs" className={classes.pageLinks}>
						<ListItem button={true} className={classes.navItem}>
							<ListItemIcon>
								<MoneyIcon />
							</ListItemIcon>
							<ListItemText primary="Grant Programs" />
						</ListItem>
					</Link>
					<Link to="/admin/organizations" className={classes.pageLinks}>
						<ListItem button={true} className={classes.navItem}>
							<ListItemIcon>
								<OrganizationIcon />
							</ListItemIcon>
							<ListItemText primary="Organizations" />
						</ListItem>
					</Link>
					<Link to="/admin/people" className={classes.pageLinks}>
						<ListItem button={true} className={classes.navItem}>
							<ListItemIcon>
								<UsersIcon />
							</ListItemIcon>
							<ListItemText primary="People" />
						</ListItem>
					</Link>
					<Link to="/admin/search" className={classes.pageLinks}>
						<ListItem button={true} className={classes.navItem}>
							<ListItemIcon>
								<SearchIcon />
							</ListItemIcon>
							<ListItemText primary="Search Config" />
						</ListItem>
					</Link>
				</ProtectedComponent>
			</List>
		</React.Fragment>
	);
};

export const Layout: React.FunctionComponent<ILayoutProps & RouteComponentProps<any>> = props => {
	const classes = useStyles();
	const [showAddModal, setShowAddModal] = useState(false);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar onAddClick={() => setShowAddModal(true)} onMenuClick={() => setIsDrawerOpen(!isDrawerOpen)} />

			<Hidden mdDown={true} implementation="css">
				<Drawer
					variant="permanent"
					anchor="left"
					classes={{
						paper: classnames('page-links', classes.drawerPaper),
					}}
					open={true}
				>
					<NavContent isLoading={props.isLoading} />
				</Drawer>
			</Hidden>
			<Hidden smUp={true} implementation="css">
				<Drawer
					variant="temporary"
					anchor="left"
					ModalProps={{ onBackdropClick: () => setIsDrawerOpen(false) }}
					classes={{
						paper: classnames('page-links', classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
					}}
					open={isDrawerOpen}
				>
					<div className={classes.toolbarIconContainer}>
						<IconButton onClick={() => setIsDrawerOpen(false)}>
							<ChevronLeftIcon className={classes.toolbarIcon} />
						</IconButton>
					</div>
					<Divider />
					<NavContent isLoading={props.isLoading} />
				</Drawer>
			</Hidden>
			<ThemeProvider theme={overallTheme => ({ ...overallTheme, ...contentTheme })}>
				<main className={classnames(classes.content)}>
					<div className={classes.appBarSpacer} />
					<SignInManager>
						<Switch>
							<Route path="/" exact={true} component={Pages.Home} />
							<Route path="/proposals/:id" component={Pages.Details} />

							<Route path="/calendar" exact={true} component={Pages.Calendar} />

							<Route path="/search/:term" component={Pages.Search} />

							<ProtectedRoute
								allowedRoles={['admin']}
								path="/admin/grant-programs"
								exact={true}
								component={AdminPages.GrantPrograms}
							/>
							<ProtectedRoute
								allowedRoles={['admin']}
								path="/admin/organizations"
								exact={true}
								component={AdminPages.Organizations}
							/>
							<ProtectedRoute
								allowedRoles={['admin']}
								path="/admin/people"
								exact={true}
								component={AdminPages.People}
							/>
							<ProtectedRoute
								allowedRoles={['admin']}
								path="/admin/search"
								exact={true}
								component={AdminPages.Search}
							/>

							<Route path="/budget-resources" component={Pages.BudgetResources} />
							<Route path="/profile" exact={true} component={Pages.Profile} />
							<Route path="/not-authorized" component={Pages.NotAuthorized} />
						</Switch>
					</SignInManager>
				</main>

				<ProtectedComponent allowedRoles={['admin']}>
					<Dialog
						maxWidth="sm"
						open={showAddModal === true}
						onClose={() => setShowAddModal(false)}
						aria-labelledby="form-dialog-title"
					>
						<DialogTitle id="form-dialog-title">Add Proposal</DialogTitle>
						<DialogContent>
							<AddProposalForm onSuccess={() => setShowAddModal(false)} />
						</DialogContent>
					</Dialog>
				</ProtectedComponent>
			</ThemeProvider>
		</div>
	);
};

export default withRouter(Layout);

import { SchemaOf, object, mixed, number } from 'yup';
import { stringSchema, selectSchema, dateSchema, numberSchema, booleanSchema, emptyStringSchema } from './BaseSchemas';
import {
	AddProposal,
	AwardedProposal,
	EditProposal,
	FacilitatedProposal,
	RejectedProposal,
	WithdrawnProposal,
} from 'Models/ProposalModels';

export const AddProposalSchema: SchemaOf<AddProposal> = object({
	proposalNumber: stringSchema('Proposal Number', 50),
	shortName: stringSchema('Short Name', 75),
	instituteId: selectSchema('Institute', 1),
	leadAgencyId: selectSchema('Lead Agency', 1),
	fundingTypeId: selectSchema('Funding Type', 1),
	fundingSourceId: selectSchema('Funding Source', 1),
});

const EditAwardedProposalSchema: SchemaOf<AwardedProposal> = object({
	awardedOn: dateSchema('Awarded On'),
	accountNumber: stringSchema('Account Number'),
	agencyNumber: stringSchema('Agency Number'),
	isExpirationDismissed: booleanSchema(),
	isExtensionFiled: booleanSchema(),
	contractSignedOn: dateSchema('Contract Signed On').notRequired(),
});

const EditRejectedProposalSchema: SchemaOf<RejectedProposal> = object({
	rejectedOn: dateSchema('Rejected On'),
	note: stringSchema('Note'),
});

const EditFacilitatedProposalSchema: SchemaOf<FacilitatedProposal> = object({
	facilitatedOn: dateSchema('Facilitated On'),
	accountNumber: stringSchema('Account Number'),
	agencyNumber: stringSchema('Agency Number'),
});

const EditWithdrawnProposalSchema: SchemaOf<WithdrawnProposal> = object({
	withdrawnOn: dateSchema('Withdrawn On'),
	note: stringSchema('Note'),
});

export const EditProposalSchema: SchemaOf<EditProposal> = object({
	id: numberSchema('Id'),
	proposalNumber: stringSchema('Proposal Number', 50),
	shortName: stringSchema('Short Name', 75),
	longName: stringSchema('Long Name', 500),
	abstract: stringSchema('Abstract'),
	startsOn: dateSchema('Starts On'),
	endsOn: dateSchema('Ends On'),
	submittedOn: dateSchema('Submitted On'),
	instituteId: selectSchema('Institute', 1),
	leadAgencyId: selectSchema('Lead Agency', 1),
	fundingTypeId: selectSchema('Funding Type', 1),
	fundingSourceId: selectSchema('Funding Source', 1),
	grantProgramId: selectSchema('Grant Program', 1),
	awardStatusId: numberSchema('Award Status', 4),
	mNumber: emptyStringSchema('M Number'),
	clientContractNumber: emptyStringSchema('Client Contract Number'),
	finalInvoiceDueOn: number(),
	awardedProposal: mixed()
		.notRequired()
		.when('awardStatusId', {
			is: 4,
			then: EditAwardedProposalSchema,
		})
		.when('awardStatusId', {
			is: 5,
			then: EditAwardedProposalSchema,
		}),
	rejectedProposal: mixed().notRequired().when('awardStatusId', {
		is: 9,
		then: EditRejectedProposalSchema,
	}),
	withdrawnProposal: mixed().notRequired().when('awardStatusId', {
		is: 11,
		then: EditWithdrawnProposalSchema,
	}),
	facilitatedProposal: mixed().notRequired().when('awardStatusId', {
		is: 6,
		then: EditFacilitatedProposalSchema,
	}),
});

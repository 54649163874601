import React from 'react';
import { ProposalModels } from 'Models';
import { AwardStatuses } from 'Models/AwardStatusModels';

import { Chip, Tooltip, Avatar } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';

export const FundedFields = (props: { proposal: ProposalModels.ProposalDetails }) => {
	if (
		(props.proposal.awardStatusId === AwardStatuses.Awarded ||
			props.proposal.awardStatusId === AwardStatuses.Completed) &&
		props.proposal.awardedProposal
	) {
		return (
			<React.Fragment>
				{props.proposal.awardedProposal.accountNumber && (
					<Chip
						size="medium"
						color="primary"
						label={props.proposal.awardedProposal.accountNumber}
						avatar={
							<Tooltip title="Account Number">
								<Avatar>$</Avatar>
							</Tooltip>
						}
					/>
				)}
				{props.proposal.awardedProposal.agencyNumber && (
					<Chip
						size="medium"
						color="default"
						label={props.proposal.awardedProposal.agencyNumber}
						avatar={
							<Tooltip title="Agency Number">
								<Avatar>
									<WorkIcon fontSize="small" />
								</Avatar>
							</Tooltip>
						}
						style={{ margin: 10 }}
					/>
				)}
				{props.proposal.mNumber && props.proposal.mNumber !== '' && (
					<Chip
						size="medium"
						color="secondary"
						label={props.proposal.mNumber}
						avatar={
							<Tooltip title="M Number">
								<Avatar>M</Avatar>
							</Tooltip>
						}
					/>
				)}
			</React.Fragment>
		);
	} else {
		return null;
	}
};

import { AddOrganization, EditOrganization } from 'Models/OrganizationModels';
import { SchemaOf, object } from 'yup';
import { stringSchema, selectSchema, numberSchema } from './BaseSchemas';

export const AddOrganizationSchema: SchemaOf<AddOrganization> = object({
	shortName: stringSchema('Acronym'),
	name: stringSchema('Name'),
	organizationTypeId: selectSchema('Organization Type', 1),
});

export const EditOrganizationSchema: SchemaOf<EditOrganization> = object({
	id: numberSchema('Id'),
	shortName: stringSchema('Acronym'),
	name: stringSchema('Name'),
	organizationTypeId: selectSchema('Organization Type', 1),
});
